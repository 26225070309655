export const deviceData = [
    {
        key:'1',
        devicename:'MV810G',
        devicenumber:'INV-993JK',
        clientname:'Susan Sumanggih',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2021-01-01',
    },
    {
        key:'2',
        devicename:'MV810G',
        devicenumber:'INV-993JK',
        clientname:'Lukman Farhan',
        vehiclenumber:'ABC-999DE',
        status:'Inactive',
        regdate:'2025-12-12',
    },
    {
        key:'3',
        devicename:'MV810G',
        devicenumber:'INV-993JK',
        clientname:'Hari Danang',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2020-08-08',
    },
]
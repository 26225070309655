import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Image } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { DeleteOutlined } from '@ant-design/icons';
import { SuccessModal } from '../SuccessModal';
const { Title, Text } = Typography
const AlertModal = ({visible,onClose,deleteitem, switchtext}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [ successmodal, setSuccessModal ] = useState(false)

    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }

    const handleSuccess = () => {
        onClose();
        setSuccessModal(true);
        setTimeout(() => {
            setSuccessModal(false)
        }, 1000);
    }
    
  return (
    <div>
        <Modal width={500} className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className={`btnsave text-white ${switchtext ? 'brand-bg' : 'bg-red' }`}
                        loading={loading}
                        onClick={
                            // DeleteProductApi();
                            switchtext ? handleSuccess : onClose
                        }
                        
                    >
                        {switchtext? 'Confirm ':'Delete' }
                    </Button>
                </Space>
            }
        >
            <Space className='text-center content-modal' align='center' size={15} direction='vertical'>
                <Image 
                    src={switchtext?'/assets/icons/confirm.png' : '/assets/icons/alert-red.png' }
                    width={50} preview={false} 
                />
                <Title level={5} className='my-0'>
                    {
                        switchtext ? (
                            'Status Update'
                        ) : (
                            'Alert'
                        )
                    }
                </Title>
                <Text className='text-center'>
                {
                    switchtext ? (
                        'Are you sure you want to change status this item?'
                    ) : (
                        <>
                            Are you sure you want to permanently delete <strong>"{ deleteitem ? deleteitem.name : ''}"</strong>?
                        </>
                    )
                }
                </Text>
            </Space>
        </Modal>
        <SuccessModal 
            successmodal={successmodal}
            onClose={()=>setSuccessModal(false)}
        />
    </div>
  )
}

export {AlertModal}
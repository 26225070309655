const deviceName = [
    {
        id: 1,
        name:'MV810G'
    },
    {
        id: 2,
        name:'MV710G'
    },
    {
        id: 3,
        name:'MV720G'
    },
]

const clientName = [
    {
        id: 1,
        name:'Susan Sumanggih'
    },
    {
        id: 2,
        name:'Lukman Farhan'
    },
    {
        id: 3,
        name:'Rina Karina'
    },
]

const clientcityName = [
    {
        id: 1,
        name:'Mardan'
    },
    {
        id: 2,
        name: 'Peshawar'
    }
]

const countryName = [
    {
        id: 1,
        name:'Saudi Arab'
    },
    {
        id: 2,
        name: 'Pakistan'
    }
]

export {deviceName,clientName,clientcityName,countryName}
import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { useNavigate } from 'react-router-dom';
import { deviceData } from '../../Data';
import { DownOutlined } from '@ant-design/icons';
import { ActionButton, AddEditDevices, AlertModal, ModuleTopHeading, SearchInput } from '../../components';

const { Text } = Typography
const Devices = () => {
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const items = [
        { label: <a href="#" className='fs-13'>A - Z</a>, key: 0 },
        { label: <a href="#" className='fs-13'>Z - A</a>, key: 1 },
      ];
  
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'devicename',
            sorter:(a,b) => a.devicename - b.devicename
        },
        {
            title: 'Device Number',
            dataIndex: 'devicenumber',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientname',
        },
        {
            title: 'Vehicle Number',
            dataIndex: 'vehiclenumber',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width:200,
            render: (status) => {
                if (status === 'Active') {
                    return <Text className='active fs-12 badge-cs'>Active</Text>;
                } else {
                    return <Text className='inactive fs-12 badge-cs'>Inactive</Text>;
                }
            },
        },
        {
            title: 'Registration Date',
            dataIndex: 'regdate',
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem(row)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete device'
                        shape="circle"
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => {setDeleteItem({name: row?.devicename})}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];
   
    return (
        <React.Fragment>
            <Card className='shadow-d radius-12'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading level={5} name='Devices' onClick={()=>setVisible(true)} />
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={[16,16]} align={'middle'}>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <SearchInput 
                                        name='name'
                                        placeholder='Search by device name'
                                        value={form.getFieldValue('name') || ''}
                                        prefix={<img src='/assets/icons/search.png' width={20} />}
                                        className='border-light-gray pad-x ps-0 radius-8 fs-13 search-w'
                                    />
                                </Col>
                                <Col lg={16} md={12} sm={24} xs={24}>
                                    <Flex justify='end'>
                                        <Dropdown
                                            menu={{items}}
                                            trigger={['click']}
                                            className='margin-top'
                                        >
                                            <Button className='btncancel pad-filter text-black mbl-btn'>
                                                Filter 
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={deviceData} 
                            scroll={{x: 1000}}
                            className='pagination'
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <AddEditDevices 
                visible={visible}
                edititem={edititem}
                onClose={()=>{setVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>setDeleteItem(false)}
            />
        </React.Fragment>
    )
}

export { Devices }

import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { clientcityName, countryName } from "../../../shared";

const { Title } = Typography;

const AddClient = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={edititem?'Edit client':'Add new client'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave bg-brand'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0">CLIENT DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Client Name'
                        name="clientname" 
                        size={'large'}
                        required
                        message='Please enter client name'
                        value={form.getFieldValue("clientname") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Phone Number'
                        name="phoneNo" 
                        size={'large'}
                        type='number'
                        required
                        message='Please enter phone number'
                        value={form.getFieldValue("phoneNo") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Country'
                        name="country" 
                        size={'large'}
                        required
                        message='Please choose country'
                        value={form.getFieldValue("country") || ''}
                        options={countryName}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'City'
                        name="city" 
                        size={'large'}
                        required
                        message='Please choose city'
                        value={form.getFieldValue("city") || ''}
                        options={clientcityName}
                    />
                </Col>
                <Col span={24}>
                    <Space className="w-100 space-cs">
                        <MyInput
                            label={"Account Number"}
                            name="accountnumber"
                            size="large"
                            type="number"
                            className="w-100"
                            value={form.getFieldValue("accountnumber") || ""}
                        />
                        <Button className="pad-x fs-13 border-gray text-black h-40 margintop-5">
                            Generate
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddClient}
export const clientDeviceData = [
    {
        key: '1',
        devicename:'MV810G',
        devicenumber:'INV-993JK',
        vehiclename:'Eicher Pro 3008',
        vehiclenumber:'ABC-999DE',
        plan:'Monthly',
        status:'Active',
        registerationdate:'11/11/2024'
    },
    {
        key: '2',
        devicename:'MV930',
        devicenumber:'INV-567HG',
        vehiclename:'LPT 1618',
        vehiclenumber:'DEW-999DE',
        plan:'Anually',
        status:'Active',
        registerationdate:'20/11/2024'
    },
    {
        key: '3',
        devicename:'MV710G',
        devicenumber:'IVD-567HG',
        vehiclename:'Eicher 10.80',
        vehiclenumber:'UYT-999DE',
        plan:'Semi-anually',
        status:'Inactive',
        registerationdate:'25/11/2024'
    },
]
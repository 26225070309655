import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Row, Space, Switch, Table, Typography } from 'antd'
import { SearchInput } from '../../Forms';
import { Link, useNavigate } from 'react-router-dom';
import { clientdashData } from '../../../Data';
import { ModuleTopHeading } from '../../PageComponents';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography
const ClientDashTable = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const items = [
        { label: <a href="#" className='fs-13'>A - Z</a>, key: 0 },
        { label: <a href="#" className='fs-13'>Z - A</a>, key: 1 },
      ];
  
    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientname',
            sorter:(a,b) => a.clientname - b.clientname
        },
        {
            title: 'Phone Number',
            dataIndex: 'phonenumber',
        },
        {
            title: 'Country',
            dataIndex: 'country',
        },
        {
            title:'Devices Registered',
            dataIndex:'devicereg'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width:200,
            render: (status) => {
                if (status === 'Active') {
                    return <Text className='active fs-12 badge-cs'>Active</Text>;
                } else {
                    return <Text className='inactive fs-12 badge-cs'>Inactive</Text>;
                }
            },
        },
        {
            title: 'Registration Date',
            dataIndex: 'regdate',
        }
    ];
   
    return (
        <div>
            <Card className='shadow-d radius-12 card-cs'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <Space>
                                <ModuleTopHeading level={5} name='Latest Billing History' />
                                <Link to={''} className='fs-12'>View All</Link>
                            </Space>
                            <Dropdown
                                menu={{items}}
                                trigger={['click']}
                                className='margin-top'
                            >
                                <Button className='btncancel pad-filter text-black mbl-btn'>
                                    Filter 
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={[16,16]} align={'middle'}>
                                <Col lg={8} md={12} sm={24} xs={24}>
                                    <SearchInput 
                                        name='name'
                                        placeholder='Search by invoice number'
                                        value={form.getFieldValue('name') || ''}
                                        prefix={<img src='/assets/icons/search.png' width={20} />}
                                        className='border-light-gray pad-x ps-0 radius-8 fs-13 search-w'
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={clientdashData} 
                            scroll={{x: 1000}}
                            className='pagination'
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { ClientDashTable }

import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Layout, Menu, Image, Space, Form, Divider, Button, Typography } from 'antd';
import { Clients, Dashboard, Devices, HelpSupport, Login, Settings, } from '../pages';
import { Notifications, UserDropdown } from '../components/Header';
import { Clientlocationview, ClientSingleview, ClientSingleView, HelpSupportSingleView, LicenseSingleView, ViewSingleUser } from '../components';

const { Header, Sider, Content } = Layout;
const RouteF = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState(['']);
  

  function getItem(label, key, icon, children) {
    return { key, icon, children, label };
  }

  useEffect(() => {
    let tab = location?.pathname?.split("/")[1];
    tab = tab === '' ? '1' :
          tab === 'clients' ? '2' :
          tab === 'devices' ? '3' :
          tab === 'helpdesk' ? '4' :
          tab === 'setting' ? '5' :
      '1';
    setCurrentTab(tab);
  }, [location]);

  useEffect(() => {
    let itemsArr = [
      getItem('Dashboard', '1', <img src="/assets/icons/dashboard.png" width={'15px'} alt="" />),
      getItem('Clients', '2', <img src="/assets/icons/clients.png" width={'18px'} alt="" />),
      getItem('Devices', '3', <img src="/assets/icons/devices.png" width={'18px'} alt="" />),
      getItem('Help Desk', '4', <img src="/assets/icons/help-desk.png" width={'18px'} alt="" />),
      getItem('Setting', '5', <img src="/assets/icons/setting.png" width={'18px'} alt="" />),
    ];

    setItems([...itemsArr]);
  }, []);


  const handleMenuClick = (e) => {
    const { key } = e;
    switch (key) {
      case '1':
        navigate("/", { replace: true });
        break;
      case '2':
        navigate("/clients", { replace: true });
        break;
      case '3':
        navigate("/devices", { replace: true });
        break;
      case '4':
        navigate("/helpdesk", { replace: true });
        break;
      case '5':
        navigate("/setting", { replace: true });
        break;
      default:
        break;
    }
  };

 
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
    // localStorage.setItem('openKeys', JSON.stringify(keys));
  };
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider breakpoint="md"
        collapsedWidth="80"
        onBreakpoint={(broken) => {
          setCollapsed(broken)
        }} trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'addclass' :''} style={{ height: '100vh', overflowY: 'auto',borderRight:'1px solid #E3E3E3'}}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ width: collapsed ? "100%" : '80px' }}
            height={'auto'}
            src="/assets/images/logo.png"
            alt='Rental Admin Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className={collapsed ? 'addmenu' :'listitem'}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background header-mbl-cs shadow-d"
          style={{
            padding: 0,
            display: 'flex',
            paddingLeft:0,
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between',alignItems:'center',position:'relative',gap:5, }}>
            <Space className='w-100 mbl-space'>
              <div className='trigger' onClick={() => setCollapsed(!collapsed)}>
                <Image src='/assets/icons/collapse.png' 
                  width={'35px'} preview={false} 
                  style={{transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)'}}  
                />
              </div>
              <Image src='/assets/icons/stars-t.png' 
                width={'25px'} preview={false} 
              />
              <Typography.Title className='m-0' level={5}>
                Welcome to Admin Panel!
              </Typography.Title>
            </Space>
            <Space size={15}>
              <Notifications />
              <UserDropdown />
            </Space>
          </div>
        </Header>
        
        <Content
          className="scroll-bar"
          style={{
            margin: '24px 10px',
            padding: '0 15px',
            minHeight: 280,
            overflowY: 'auto',
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/clientview/:clientId" element={<ClientSingleview />} />
            <Route path="/clients/clientview/:clientId/:locationId" element={<Clientlocationview />} />
            <Route path="/devices" element={<Devices />} />
            <Route path='/setting' element={<Settings />} />
            <Route path='/helpdesk' element={<HelpSupport />} />
            <Route path='/helpdesk/viewhelp/:helpId' element={<HelpSupportSingleView />} />
           </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RouteF;
